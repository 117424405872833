import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'start',
    pathMatch: 'full'
  },
  {
    path: 'start',
    loadChildren: () => import('./pages/start/start.module').then(m => m.StartPageModule)
  },
  {
    path: 'stammdaten',
    loadChildren: () => import('./pages/stammdaten/stammdaten.module').then(m => m.StammdatenPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'bankdaten', 
    loadChildren: () => import('./pages/bankdaten/bankdaten.module').then(m => m.BankdatenPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'umfang',
    loadChildren: () => import('./pages/umfang/umfang.module').then(m => m.UmfangPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'bestandsaufnahme',
    loadChildren: () => import('./pages/bestandsaufnahme/bestandsaufnahme.module').then(m => m.BestandsaufnahmePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'datenschutz',
    loadChildren: () => import('./pages/datenschutz/datenschutz.module').then(m => m.DatenschutzPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'frei',
    loadChildren: () => import('./pages/frei/frei.module').then(m => m.FreiPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'zusammenfassung',
    loadChildren: () => import('./pages/zusammenfassung/zusammenfassung.module').then(m => m.ZusammenfassungPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'einwilligung',
    loadChildren: () => import('./pages/einwilligung/einwilligung.module').then(m => m.EinwilligungPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'unterschrift',
    loadChildren: () => import('./pages/unterschrift/unterschrift.module').then(m => m.UnterschriftPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'datenschutzerklaerung',
    loadChildren: () => import('./pages/datenschutzerklaerung/datenschutzerklaerung.module').then( m => m.DatenschutzerklaerungPageModule)
  },
  {
    path: 'impressum',
    loadChildren: () => import('./pages/impressum/impressum.module').then( m => m.ImpressumPageModule)
  },
  {
    path: 'consentdescription',
    loadChildren: () => import('./pages/consentdescription/consentdescription.module').then( m => m.ConsentDescriptionPageModule)
  },
  {
    path: '**',
    redirectTo: 'start'
  },
  {
    path: 'frei',
    loadChildren: () => import('./pages/frei/frei.module').then( m => m.FreiPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
