import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";

@Injectable({
 providedIn: "root",
})
export class AppContextService {
 context = {
  vollmacht: {
   anrede: null,
   anzahl_erinnerungen: null,
   buro_id: null,
   c_o: null,
   created_at: null,
   email: null,
   einwilligungen: null,
   geburtsdatum: null,
   id: null,
   key: null,
   key_valid_until: null,
   kundennummer: null,
   land: null,
   mobil: null,
   name: null,
   ort: null,
   plz: null,
   strasse: null,
   titel: null,
   ts_abgelehnt: null,
   ts_finished: null,
   ts_lastlogin: null,
   ts_letzte_erinnerung: null,
   ts_letzter_export: null,
   unterschrift: null,
   unterschrift_vm: null,
   updated_at: null,
   user_id: null,
   vollmachten: {
    maklervertrag: null,
    vertragsbestand: {
     vertraege: [],
    },
    datenschutz: {
     kommunikation: {
      brief: true,
      telefon: false,
      fax: false,
      email: false,
      whatsapp: false,
     },
     beratung_weitere_produkte: false,
     datenweitergabe_an_personen: null,
    },
    frei: {
     title: null,
     description: null,
     template: null,
     attachment: null,
    },
   },
   vollmachtstypen: null,
   vorname: null,
  },
  alreadySigned: false,
  key: null,
  models: null,
  consent: null,
  customizing: {
   start_tile: null,
   start_text: null,
   logo: {
    name: null,
    mime: null,
    data: null,
   },
  },

  isPlatformDesktop: false,
  isPlatformMobile: false,
  isLoggedIn: false,
  isStammdatenVisited: false,
  isBankdatenVisited: false,
  isUmfangVisited: false,
  isBestandsaufnahmeVisited: false,
  isDatenschutzVisited: false,
  isFreiVisited: false,
  isZusammenfassungVisited: false,
  isEinwilligungVisited: false,
  isUnterschriftVisited: false,
  privatDisplayString: "",
  berufDisplayString: "",
  investmentDisplayString: "",
  kommunikationDisplayString: "",
 };

 cfgBottomDrawer = {
  start: {
   show: false,
   index: 0,
  },
  stammdaten: {
   show: false,
   index: 0,
  },
  bankdaten: {
   show: false,
   index: 0,
  },
  umfang: {
   show: false,
   index: 0,
  },
  bestandsaufnahme: {
   show: false,
   index: 0,
  },
  datenschutz: {
   show: false,
   index: 0,
  },
  frei: {
   show: false,
   index: 0,
  },
  zusammenfassung: {
   show: false,
   index: 0,
  },
  einwilligung: {
   show: false,
   index: 0,
  },
  unterschrift: {
   show: false,
   index: 0,
  },
 };

 cfgZusammenfassung = {
  start: {
   show: false,
   index: 0,
  },
  stammdaten: {
   show: false,
   index: 0,
  },
  bankdaten: {
   show: false,
   index: 0,
  },
  umfang: {
   show: false,
   index: 0,
  },
  bestandsaufnahme: {
   show: false,
   index: 0,
  },
  datenschutz: {
   show: false,
   index: 0,
  },
  frei: {
   show: false,
   index: 0,
  },
  zusammenfassung: {
   show: false,
   index: 0,
  },
  einwilligung: {
   show: false,
   index: 0,
  },
  unterschrift: {
   show: false,
   index: 0,
  },
 };

 initBottomDrawer() {
  const models = this.context.models;
  var pages = Array("start", "einwilligung", "unterschrift");

  for (let key in models) {
   if (key == "md") {
    if (this.context.models.md.iban) pages = pages.concat(["stammdaten", "bankdaten", "zusammenfassung"]);
    else pages = pages.concat(["stammdaten", "zusammenfassung"]);
   }

   if (key == "bue")
    if (this.context.models.bue && this.context.models.bue.vtlist && this.context.models.bue.vtlist.data && this.context.models.bue.vtlist.data.length > 0)
     pages = pages.concat(["bestandsaufnahme", "zusammenfassung"]);

   if (key == "dkomm") {
    if (this.context.models.dkomm) pages = pages.concat(["stammdaten", "datenschutz"]);
   }
  }

  const fvbPages = ["datenschutz", "zusammenfassung", "einwilligung", "unterschrift"];
  var index = 0;
  for (let key in this.cfgBottomDrawer) {
   if (pages.indexOf(key) > -1) {
    this.cfgZusammenfassung[key].show = true;
    if (fvbPages.indexOf(key) > -1) {
     this.cfgBottomDrawer[key].show = true;
     this.cfgBottomDrawer[key].index = index;
     index++;
    }
   }
  }
 }

 getNextPage(actual: string) {
  var found = false;
  for (let key in this.cfgBottomDrawer) {
   if (key == actual) {
    found = true;
   } else {
    if (found && this.cfgBottomDrawer[key].show) {
     return key;
    }
   }
  }
  return null;
 }

 constructor(public platform: Platform) {
  this.context.isPlatformDesktop = this.platform.is("desktop");
  this.context.isPlatformMobile = this.platform.is("mobileweb") || this.platform.is("mobile");
 }
}
